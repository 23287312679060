import axios from 'axios'

const serverApi = axios.create({
  // baseURL: 'http://localhost:8000/api/',
  // baseURL: 'https://apptb.nihonuwu.com/api/',
  // baseURL: 'https://bangkit.nihonuwu.com/api/',
  baseURL: 'https://bangkit.nusia.id/api/',
  // baseURL: 'https://dev.nusia.id/api/',
})

const cors = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
    // 'Content-Type': 'multipart/form-data',
  }
};

const actions = {
// Login
  async fetchLoginAuth({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('login', formData, cors);
      const data = response.data;
      commit('loginAuth', data); // Panggil mutasi SET_DATA untuk menyimpan data
      return response;
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - login

  async fetchLogoutAuth({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('logout', formData, cors);
      const data = response.data;
      commit('logoutAuth', data); // Panggil mutasi SET_DATA untuk menyimpan data
      return response;
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - logout
// Barang Gambar
  async fetchIndexBarangGambar({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-gambar', cors);
      const data = response.data;
      commit('indexBarangGambar', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreBarangGambar({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('barang-master/store', formData, cors);
      const data = response.data;
      commit('storeBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchShowBarangGambar({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-master/'+ id +'/show', cors);
      const data = response.data;
      commit('showBarangMaster', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateBarangGambar({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`barang-master/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateBarangMaster', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeleteBarangGambar({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('barang-master/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteBarangMaster', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete
// Filter Barang
  async fetchIndexFilterBarang({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('filter-barang', cors);
      const data = response.data;
      commit('indexFilterBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
// Temp Order
  async fetchIndexTempOrder({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('temporder', cors);
      const data = response.data;
      commit('indexTempOrder', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreOrder({ commit }, formData) {
    try {
      const response = await serverApi.post('order/store', formData, cors);
      const data = response.data;
      commit('storeOrder', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchStoreTempOrder({ commit }, formData) {
    try {
      const response = await serverApi.post('temporder/store', formData, cors);
      const data = response.data;
      commit('storeTempOrder', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchDeleteTempOrder({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('temporder/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteTempOrder', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete

// Order
  async fetchIndexOrder({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('order', cors);
      const data = response.data;
      commit('indexOrder', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

// Order Detail
  async fetchIndexOrderDetail({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('order-detail', cors);
      const data = response.data;
      commit('indexOrderDetail', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

// Pelanggan
  async fetchIndexPelanggan({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('pelanggan', cors);
      const data = response.data;
      commit('indexPelanggan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStorePelanggan({ commit }, formData) {
    try {
      const response = await serverApi.post('pelanggan/store', formData, cors);
      const data = response.data;
      commit('storePelanggan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchShowPelanggan({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('pelanggan/'+ id +'/show', cors);
      const data = response.data;
      commit('showPelanggan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdatePelanggan({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`pelanggan/${id}/update`, formData, cors);
      const data = response.data;
      commit('updatePelanggan', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeletePelanggan({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('pelanggan/'+ id +'/delete', cors);
      const data = response.data;
      commit('deletePelanggan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete

// Pembayaran
  async fetchIndexPembayaran({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('pembayaran', cors);
      const data = response.data;
      commit('indexPembayaran', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

// Metode Pembayaran
async fetchIndexMetodePembayaran({ commit }) {
  try {
    // Panggil API di sini
    const response = await serverApi.get('metode-pembayaran', cors);
    const data = response.data;
    commit('indexMetodePembayaran', data); // Panggil mutasi SET_DATA untuk menyimpan data
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}, // get - index

// Barang Harga
  async fetchIndexBarangHarga({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-harga', cors);
      const data = response.data;
      commit('indexBarangHarga', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreBarangHarga({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('barang-harga/store', formData, cors);
      const data = response.data;
      commit('storeBarangHarga', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchShowBarangHarga({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-harga/'+ id +'/show', cors);
      const data = response.data;
      commit('showBarangHarga', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchDeleteBarangHarga({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('barang-harga/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteBarangHarga', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete

// Barang Master [done]
  async fetchIndexBarangMaster({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-master', cors);
      const data = response.data;
      commit('indexBarangMaster', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreBarangMaster({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('barang-master/store', formData, cors);
      const data = response.data;
      commit('storeBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchShowBarangMaster({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-master/'+ id +'/show', cors);
      const data = response.data;
      commit('showBarangMaster', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateBarangMaster({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`barang-master/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateBarangMaster', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeleteBarangMaster({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('barang-master/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteBarangMaster', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete
  
// Barang
  async fetchIndexBarang({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang', cors);
      const data = response.data;
      commit('dataBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreBarang({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('barang/store', formData, cors);
      const data = response.data;
      commit('storeBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
      // this.router.push('/barang');
      // return response; // Mengembalikan respons
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
      // const errorImage = false;
      // // console.error('Error fetching data:', error);
      // if (error.response && error.response.status === 500) {
      //   // console.error('Internal Server Error:', error);
      //   // Handle kesalahan 500 di sini, misalnya menampilkan pesan error kepada pengguna
      //   // alert('Terjadi kesalahan, Data Tidak Ditemukan.');
      //   return errorImage;
      //   //
      // } if (error.response && error.response.status === 404) {
      //   //
      //   return errorImage;
      //   //
      // } else {
      //   console.error('Error fetching data:', error);
      // }
    }
  }, // post - store
  async fetchShowBarang({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang/'+ id +'/show', cors);
      const data = response.data;
      commit('showBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateBarang({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`barang/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateBarang', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeleteBarang({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('barang/'+ id +'/delete', cors);
      const data = response.data;
      commit('updateBarang', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete

  // harga barang modal
  async fetchIndexHargaBarangModal({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('supplier', cors);
      const data = response.data;
      commit('indexHargaBarangModal', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreHargaBarangModal({ commit }, formData) {
    try {
      const response = await serverApi.post('harga-barang-modal/store', formData, cors);
      const data = response.data;
      commit('storeHargaBarangModal', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
      // const errorImage = false;
      // if (error.response && error.response.status === 500) {
      //   return errorImage;
      // } if (error.response && error.response.status === 404) {
      //   return errorImage;
      // } else {
      //   console.error('Error fetching data:', error);
      // }
    }
  }, // post - store
  async fetchShowHargaBarangModal({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('harga-barang-modal/'+ id +'/show', cors);
      const data = response.data;
      commit('showHargaBarangModal', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateHargaBarangModal({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`harga-barang-modal/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateHargaBarangModal', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeleteHargaBarangModal({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('harga-barang-modal/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteHargaBarangModal', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete

  // barang satuan
  async fetchIndexBarangSatuan({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-satuan', cors);
      const data = response.data;
      commit('indexBarangSatuan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreBarangSatuan({ commit }, formData) {
    try {
      const response = await serverApi.post('barang-satuan/store', formData, cors);
      const data = response.data;
      commit('storeBarangSatuan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
      // if (error.response && error.response.status === 500) {
      //   return errorImage;
      // } if (error.response && error.response.status === 404) {
      //   return errorImage;
      // } else if (error.response.status === 422) {
      //   return error;
      //   // console.log('Error 422:', error.response.data.message);
      //   // console.error('Error updating data:', error);
      //   // throw error;
      //   // throw new Error(error.response.data.message);
      // } else {
      //   console.error('Error fetching data:', error);
      // }
      // const errorMessage = error.response ? error.response.data.message : 'An error occurred';
      // const customError = new Error(errorMessage);
      // return { error, errorImage };
      // return errorImage;
    }
  }, // post - store
  async fetchShowBarangSatuan({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-satuan/'+ id +'/show', cors);
      const data = response.data;
      commit('showBarangSatuan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateBarangSatuan({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`barang-satuan/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateBarangSatuan', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeleteBarangSatuan({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('barang-satuan/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteBarangSatuan', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete

  // V.1.1.4

  // Barang Stok
  async fetchIndexBarangStok({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-stok', cors);
      const data = response.data;
      commit('indexBarangStok', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

  async fetchStoreBarangStok({ commit }, formData) {
    try {
      // Panggil API di sini
      const response = await serverApi.post('barang-stok/store', formData, cors);
      const data = response.data;
      commit('storeBarangStok', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchShowBarangStok({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('barang-stok/'+ id +'/show', cors);
      const data = response.data;
      commit('showBarangStok', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateBarangStok({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`barang-stok/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateBarangStok', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  // async fetchDeleteBarangStok({ commit },id) {
  //   try {
  //     // Panggil API di sini
  //     const response = await serverApi.delete('barang-master/'+ id +'/delete', cors);
  //     const data = response.data;
  //     commit('deleteBarangStok', data); // Panggil mutasi SET_DATA untuk menyimpan data
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }, // delete - delete

  // Log Barang Stok
  async fetchIndexLogBarangStok({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('log-barang-stok-masuk', cors);
      const data = response.data;
      commit('indexLogBarangStok', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

  // Log Barang Stok Masuk
  async fetchIndexLogBarangStokMasuk({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('log-barang-stok-masuk', cors);
      const data = response.data;
      commit('indexLogBarangStokMasuk', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

  // Pengiriman
  async fetchIndexPengiriman({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('pengiriman', cors);
      const data = response.data;
      commit('indexPengiriman', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index

  // Supplier
  async fetchIndexSupplier({ commit }) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('supplier', cors);
      const data = response.data;
      commit('indexSupplier', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - index
  async fetchStoreSupplier({ commit }, formData) {
    try {
      const response = await serverApi.post('supplier/store', formData, cors);
      const data = response.data;
      commit('storeSupplier', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      // Status HTTP 422 Unprocessable Entity
      if (error.response && error.response.status === 422) { 
        throw error;
      }
    }
  }, // post - store
  async fetchShowSupplier({ commit }, id) {
    try {
      // Panggil API di sini
      const response = await serverApi.get('supplier/'+ id +'/show', cors);
      const data = response.data;
      commit('showSupplier', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // get - show
  async fetchUpdateSupplier({ commit }, { id, formData }) {
    console.log("FormData:", formData);
    try {
      // Call API here
      const response = await serverApi.put(`supplier/${id}/update`, formData, cors);
      const data = response.data;
      commit('updateSupplier', data); // Call SET_DATA mutation to save data
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }, // put - update
  async fetchDeleteSupplier({ commit },id) {
    try {
      // Panggil API di sini
      const response = await serverApi.delete('supplier/'+ id +'/delete', cors);
      const data = response.data;
      commit('deleteSupplier', data); // Panggil mutasi SET_DATA untuk menyimpan data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, // delete - delete
};

export default actions;